//NavLinks component
import React from 'react';
import { NavLink } from 'react-router-dom'; //NavLink from react-router-dom

import './NavLinks.css';    //CSS styles

function NavLinks(props) {

    return (
        <ul className='nav-links'>
            <li>
                <NavLink to='/' exact>HOME</NavLink>
            </li>
            <li>
                <NavLink to='/documentos'>DOCUMENTOS</NavLink>
            </li>
            <li>
                <NavLink to='/perguntas' exact>PERGUNTAS FREQUENTES</NavLink>
            </li>
            <li>
                <NavLink to='/contacto' exact>CONTACTO</NavLink>
            </li>
            <li>
                <a className='icon-nav' href='https://wa.me/351967323907?text=' target='ablank'>
                    <img href='https://wa.me/351967323907?text=' alt='icone whatsapp' src='/images/whatsapp.svg' />
                </a>
            </li>
        </ul>
    );
}

export default NavLinks;