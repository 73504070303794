import React from 'react';

import './Documentos.css';

function Documentos() {
    return (
        <div>
            <h1 style={{textAlign:'center'}}>Aqui encontra todos os documentos necessários:</h1>
            <div className='documents-container'>  
                <p>Documento para Registo Automóvel, utilizado para registar a propriedade quando compra ou vende um veículo automóvel:</p>             
                <iframe className='document-item' src="/documents/DUA_modelo_unico.pdf" width="100%" height="500px" title='DUA' />
                <p>Documento Modelo 9 do IMT, utilizado para pedido de alteração de características de um veículo automóvel junto do IMT:</p>
                <iframe className='document-item' src="/documents/Modelo_9_IMT.pdf" width="100%" height="500px" title ='Modelo 9 IMT'/>
            </div>
            <div className='links-container'>
                <p>Documento para Registo Automóvel, utilizado para registar a propriedade quando compra ou vende um veículo automóvel:</p>
                <a href='/documents/DUA_modelo_unico.pdf'>Documento Unico Automóvel</a>
                <p>Documento Modelo 9 do IMT, utilizado para pedido de alteração de características de um veículo automóvel junto do IMT:</p>
                <a href='/documents/Modelo_9_IMT.pdf'>Modelo 9 IMT</a>
            </div>
        </div>
    );
}

export default Documentos;