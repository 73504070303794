import React from 'react';

import Accordion from 'react-bootstrap/Accordion';

import './FAQ.css';

import {perguntas} from './perguntas';

function FAQ() {
    return (
        <div className='faq-content'>
            <Accordion defaultActiveKey="0">
                {perguntas.map((pergunta, index) => (
                    <Accordion.Item eventKey={pergunta.key} key={index} className='accordion-header'>
                        <Accordion.Header >{pergunta.header.toUpperCase()}</Accordion.Header>
                        <Accordion.Body className='accordion-header'>
                            <div style={{whiteSpace: "pre-wrap"}}>
                                {pergunta.body}
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                ))}
            </Accordion>
        </div>
    );
}

export default FAQ;