// const apiUrl = process.env.REACT_APP_API_URL;
const apiV2Url = process.env.REACT_APP_API_V2_URL;

// email service
export async function sendEmail({
  marca,
  modelo,
  ano,
  combustivel,
  caixaVel,
  numPortas,
  kms,
  matricula,
  valor,
  comentarios,
  equipamentos,
  nome,
  email,
  telefone,
  fotos,
}) {
  // use the javsactipt fetch api to call the API (POST)
  // const res = await fetch(apiUrl, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //   },
  //   body: JSON.stringify({
  //     message: `\nMarca - ${marca}\nModelo - ${modelo}\nAno - ${ano}\nCombustivel - ${combustivel}\nCaixa de velocidades - ${caixaVel}\nNumero de portas - ${numPortas}\nQuilometragem - ${kms}\nMatricula - ${matricula}\nValor pretendido - ${valor}\nComentários - ${comentarios}\nLista de equipamentos - ${equipamentos}\nNome - ${nome}\nEmail - ${email}\nTelefone - ${telefone}\nFotos - ${fotos}
  //     `,
  //   }),
  // });

  // take array of equipamentos and convert to string
  let equipamentosString = "";

  // loop through array and add to string IF array is not empty
  if (equipamentos !== "Não especificado" && equipamentos.length > 0) {
    equipamentos.forEach((equipamento) => {
      equipamentosString += `${equipamento}, `;
    });

    if(equipamentosString.length > 0) {
      // remove last ', ' from string
      equipamentosString = equipamentosString.slice(0, -2);
    }
  } else {
    equipamentosString = "Não especificado";
  }

  // POST to API V2 (add to DB)
  const res2 = await fetch(apiV2Url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      nome,
      email,
      telefone,
      marca,
      modelo,
      ano: parseInt(ano),
      combustivel,
      caixa_vel: caixaVel,
      num_portas: parseInt(numPortas),
      kms: parseInt(kms),
      matricula,
      valor: parseInt(valor),
      fotos,
      comentarios,
      equipamentos: equipamentosString,
    }),
  });

  return res2;
}
