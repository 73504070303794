import React from 'react';

import Form from 'react-bootstrap/Form';

import './Textarea.css';

function Textarea(props) {
    return (
        <Form.Group className="mb-3" controlId={props.id}>
            <Form.Label className='label'>{props.label}</Form.Label>
            <Form.Control ref={props.inputRef} as="textarea" placeholder={props.placeholder} rows={3} />
        </Form.Group>
    );
}

export default Textarea;