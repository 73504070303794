import React from 'react';

import Card from '../shared/UIElements/Card';

import './Contacto.css';

function Contacto() {
    return (
        <Card className='card-content'>
            <h1>ONDE ESTAMOS</h1>
            <hr />
            <div className='content'>
                <div className='map'>
                    <iframe title='mapa' className='map-content' frameBorder="0" scrolling="yes" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=720&amp;height=600&amp;hl=en&amp;q=Praceta%20das%20Flores%20Nr%208%20RC%20Loja,%202610-074%20Amadora+(On%20Box%20Carcenter)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed">                        
                    </iframe>
                </div>
                <div className='contactos'>
                    <h3>
                        Morada: <br/>
                        Praceta das Flores Nº 8 Loja<br/>
                        Quinta Grande de Alfragide<br/>
                        2610-074 Amadora
                    </h3>
                    <h3>
                        Contactos: <br />
                        962 155 784<br />
                        215 838 788<br />
                        <a className='icon-contact' href='https://wa.me/351967323907?text=' target='ablank'>
                            <img href='https://wa.me/351967323907?text=' alt='icone whatsapp' src='/images/whatsapp.svg' />
                        </a>
                    </h3>
                </div>
            </div>
        </Card>

    );
}

export default Contacto;