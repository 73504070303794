export const perguntas = [
    {
        key: '0',
        header: 'Quais os documentos necessários que preciso ter para vender o meu carro?',
        body: `Cartão do Cidadão / Titulo de Residencia ou Passaporte e NIF; 
– Impostos em dia; 
– DUA (Documento Único do Automovel; 
– Folha de IPO válida;        
– Pagamento do IUC atualizado;       
– Matrícula portuguesa (não compramos carros com matrícula estrangeira);
– Nunca ter sido considerado «perda total» num sinistro;        
– Quilometragem não viciada;        
– Manuais e livro de revisões;    
– Roda de socorro, triângulo e ferramentas;       
– Duplicado de chaves.      
Para mais esclarecimentos sobre os nossos requisitos de avaliação de carro usado, consulte a nossa lista de Perguntas Frequentes, ou entre em contacto 
connosco por telefone ou por e-mail.`
    },
    {
        key: '1',
        header: 'ONDE DEVO VENDER O MEU CARRO USADO?',
        body: `É do senso comum que a venda a um particular é onde tradicionalmente se consegue obter o melhor valor, mas estas vendas aumentam significativamente o risco de ter problemas com a venda do seu automóvel.

Problemas por dar informações pessoais a individuos que não conhece, problemas com pagamentos, multas, portagens entre outros problemas de segurança pessoal e patrimonial.        

A melhor opção é vender o seu carro usado a um profissional do ramo automóvel.

Venda o seu carro na compramoscarrosusados.com, ao melhor preço de mercado, de forma rápida e 100% segura.`
    },
    {
        key: '2',
        header: 'Devo vender a um concessionário?',
        body: `Os concessionários apenas compram carros usados como retoma da compra de um carro novo.

Tradicionalmente os valores atribuídos aos carros usados por parte dos concessionarios são inferiores aos valores atribuídos pela compramoscarrosusados.com
        
Na compramoscarrosusados.com compramos carros usados sem ter que lhe vender outra viatura.`
    },
    {
        key: '3',
        header: 'Existe alguma taxa ou custo para vender o meu carro usado?',
        body: `Não, desde o primeiro passo de avaliação online do seu veículo usado, até ao processo final da formalização da venda, é totalmente gratuito.`
    },
    {
        key: '4',
        header: 'Quais os passos do processo de venda?',
        body: `Apenas 3 etapas muitos simples e já está!

• 1- Avaliação online gratuita, introduza aqui a matricula do seu carro usado e preencha os dados solicitados. Pode também optar também pelo contacto telefónico através do numero : 962 155 784 (atendimento das 9h às 20h todos os dias úteis) ou através do nosso mail compras@compramoscarrosusados.com
        
• 2- Verificação fisica do veículo e acordo de proposta entre as partes.
        
• 3 - Processo de Venda final do veículo , nesta fase é efectuada de imediato a transferência de propriedade para o nosso nome e pagamento do valor acordado ao proprietário do veículo.
        
Importante:
        
• Para efectivar a venda, assegure-se que traz consigo as chaves do seu veículo, DUA (Documento Único Automóvel) do veículo, comprovativo de pagamento do IUC e, caso se aplique, ultimo boletim de inspeção válido.`
    },
    {
        key: '5',
        header: 'Quais as vantagens de vender o meu carro usado na compramoscarrosusados.com?',
        body: `• Disponibilizamos um serviço rápido, confortável e seguro que o liberta de todos os problemas e dolongo tempo associado a um processo de venda de uma viatura usada.

• Somos um operador de compra de veículos usados composto por profissionais altamente qualificados, independentes de concessionários de marca, estamos focados em apresentar-lhe de forma rápida as melhor e mais justa proposta do mercado.
        
• O seu carro usado é trocado por dinheiro (transferencia bancária), de forma rápida, transparente e honesta sem sem qualquer outro negócio pelo meio.`
    },
    {
        key: '6',
        header: 'Posso vender um carro que está em nome de uma empresa?',
        body: `Sim, a compramoscarrosusados.com compra viaturas que estejam registadas em nome de particulares ou empresas.` 
    },
    {
        key: '7',
        header: 'A compramoscarrosusados.com compra veículos com matrícula estrangeira?',
        body: `Não, a compramoscarrosusados.com apenas compra carros com matrícula portuguesa.`
    },
    {
        key: '8',
        header: 'E se o meu carro for importado?',
        body: `Na compramoscarrosusados.com , adquirimos qualquer veículo que tenha matricula Portuguesa e tenha toda a documentação esteja legal.

Salientamos apenas que o mercado aplica um coeficiente de desvalorização adicional a estas viaturas face a carros “Nacionais”.`
    },
    {
        hey: '9',
        header: 'Ainda estou a pagar o empréstimo, posso vendê-lo à compramoscarrosusados.com',
        body: `Sim, na compramoscarrosusados.com adquirimos viaturas com reserva de propriedade / locação financeira ou hipoteca.

Disponibilizamos o apoio necessário para tratamento junto do banco ou financeira que financiou o veículo. Liquidamos o valor do financiamento e transferimos de imediato o valor restante para o proprietário.`
    },
    {
        key: '10',
        header: 'Posso vender à compramoscarrosusados.com um veículo herdado?',
        body: `Sim pode. Deverá ter na sua posse, para além do DUA (Documento Unico Automóvel), última ficha de inspeção, comprovativo de pagamento do IUC, a habilitação de herdeiros, bem como o a relação de bens declarados às finaças onde deverá constar a viatura objecto de venda.

Todos os herdeiros terão que assinar o MUA( Modelo Unico Automóvel), também conhecido por declaração de venda.`
    },
    {
        key: '11',
        header: 'Compram um veículo que não esteja no meu nome?',
        body: `Sim compramos. Deverá trazer consigo, no momento da venda, uma procuração assinada e reconhecida por serviço competente onde conste explicitamente que o proprietário da viatura o autoriza um terceiro (pessoa que está a tratar do processo de venda) a transacionar a viatura livre de qualquer ónus ou encargo.

Em alternativa à procuração, no momento da efectivação da venda, pode fazer-se acompanhar da pessoa titular do registo de propriedade do veículo.`
    },
    {
        key: '12',
        header: 'Também compram viaturas comerciais?',
        body: `Sim, compramos viaturas comercias e viaturas de passageiros.`
    },
    {
        key: '13',
        header: 'Qual o horário de atendimento?',
        body: `Escritório: das 10h00 às 18h00

Centro de Avaliação:
• Alfragide: Segunda a Sexta-feira das 9h30 às 19h00; Sábado das 9h30 às 15h30.`
    },
    {
        key: '14',
        header: 'Compram todos os carros?',
        body: `Sim, a compramoscarrosusados.com compra viaturas que estejam em boas condições gerais para voltarem a ser revendidas no mercado automóvel onde operamos.

Ressalvamos que não adquirimos viaturas provenientes de serviço de Táxi, bem como viaturas acidentadas, Salvados/Perdas Totais ou viaturas com Kms adulterados.
        
Há ainda viaturas que pela sua natureza, antiguidade, vicios ocultos, baixo valor comercial, será racionalmente dificil apresentar uma proposta atrativa de compra, nestes casos será enviado um email ao cliente a informar da impossibilidade de efectuar a compra da viatura em causa.
        
A compramoscarrosusados.com reserva-se ao direito de não adquirir viaturas que estejam desenquadradas com as policiticas internas de compra.`
    },
    {
        key: '15',
        header: 'Compram carros acidentados, salvados/perdas totais?',
        body: `Não, a compramoscarrosusados.com não compra este tipo de viaturas.`
    },
    {
        key: '16',
        header: 'Quanto tempo demora o processo de transacção do veículo?',
        body: `Na compramoscarrousados.com, todo o processo pode ser tratado de forma segura em apenas algumas horas desde que possua todos os documentos da vitura que permitam a transação (DUA, Ficha de inspeção, Chaves, Comprovativo de pagamento de IUC)

A rapidez do processo não depende apenas da compramoscarrosusados.com , depende em boa parte da disponibilidade do cliente vendedor.

Caso possua todos os elementos necessários, o processo é imediato.`
    },
    {
        key: '17',
        header: 'Para que servem o meu endereço de email e o meu telefone?',
        body: `Necessitamos de um email para poder comunicar-lhe a avaliação online que nos pede.

Em caso de aceitação da proposta, resultadante da avaliação online, poderá ser necessário o seu número de telefone para agendamento da verificação fisica e conclusão da venda da sua viatura ou até, para de uma forma mais célere podermos tirar alguma dúvida relativamente à viatura.
        
Estes elementos serão cuidadosamente acautelados e apenas serão utilizados para o fim a que se destinam de acordo com os nossos regulamentos de protecção de dados.`
    }
];