import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";

import MainNavigation from "./shared/Navigation/MainNavigation";
import Home from "./home/Home";
import Contact from "./contacto/Contacto";
import FAQ from "./FAQ/FAQ";
import Documentos from "./documentos/Documentos";
import Footer from "./shared/Footer/Footer";
import Sobre from "./sobre/Sobre";
import Outros from "./outros/Outros";

function App() {
  return (
    <Router>
      <MainNavigation />
      <main>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/perguntas" exact>
            <FAQ />
          </Route>
          <Route path="/contacto">
            <Contact />
          </Route>
          <Route path="/documentos">
            <Documentos />
          </Route>
          <Route path="/sobre">
            <Sobre />
          </Route>
          <Route path="/outros">
            <Outros />
          </Route>
          <Redirect to="/" />
        </Switch>
      </main>
      <Footer />
    </Router>
  );
}

export default App;
