import React from "react";
import Card from "../shared/UIElements/Card";
import "../contacto/Contacto.css";

const Outros = () => {
  return (
    <Card className="card-content">
      <h1>OUTROS</h1>
      <hr />
      <div className="sobre" id="termos">
        <h3 className="subtitulo">Termos e Condições</h3>
        <h5>1 - Generalidades e objeto do contrato</h5>
        <div className="sobre">
          (1) A página www.compramoscarrosusados.com é propriedade de On Box,
          Unipessoal, Lda., com sede na Praceta das Flores Nº 8 Loja – Alfragide
          – 2610-074 Amadora – NIF 510531407 – Capital Social 20.000 eur.
          Através deste sítio web, gostaríamos de informar os nossos
          consumidores e outros visitantes (de agora em diante, “utilizadores”)
          acerca dos serviços que oferecemos. <br />
          (2) O nosso website permite aos utilizadores introduzir determinada
          informação sobre um veículo no nosso formulário de avaliação, para
          obter uma cotação on-line sobre o mesmo. A dita avaliação é gratuita.{" "}
          <br />
          (3) As condições gerais de uso presentes (de agora em diante, "Termos
          e Condições") aplicam-se assim que utiliza os serviços da nossa página
          web. Estes implicam a aceitação dos Termos e Condições. Reservamo-nos
          o direito de modificar os presentes Termos e Condições em qualquer
          momento, sem necessidade de informar o motivo. <br />
        </div>
        <br />
        <h5>2 - Avaliação on-line</h5>
        <div className="sobre">
          (1) O nosso website permite aos utilizadores introduzir determinada
          informação sobre um veículo no nosso formulário de avaliação, para
          obter uma cotação on-line sobre o mesmo, a qual é gratuita. Esta serve
          unicamente para que receba informação estimativa acerca do preço de
          compra do veículo. <br />
          (2) A avaliação on-line na nossa página web não constitui uma oferta
          vinculativa por parte da www.compramoscarrosusados.com, nem uma oferta
          de venda vinculativa para o proprietário. <br />
          (3) A compra da viatura, pela compramoscarrosusados.com ou por
          empresas associadas, só pode ser feita depois de uma cuidadosa
          avaliação do veículo por parte dos nossos especialistas e/ou numa das
          nossas lojas em Portugal. <br />
          (4) A avaliação on-line faz-se através da nossa página web, e o
          resultado, baseado na informação relativa ao veículo, será enviado
          para o endereço de correio eletrónico do utilizador. <br />
        </div>
        <br />
        <h5>3 - Direito de uso</h5>
        <div className="sobre">
          (1) Quando fazem uso do nosso formulário de avaliação on-line, ao
          abrigo destas condições de utilização, os utilizadores têm o direito
          de ver os dados pessoais no seu próprio ecrã e de fazer uma cópia
          deste (print screen, por exemplo), para que possam guardar a
          informação a longo prazo. <br />
          (2) O uso só é permitido para fins pessoais, não comerciais. Em
          nenhuma medida o utilizador pode usar os dados obtidos on-line para
          criar a sua própria base de dados num meio de comunicação. O uso
          comercial dos seus dados, assim como a sua difusão, está proibido.{" "}
          <br />
          (3) As atividades dos utilizadores que têm como fim minar o
          funcionamento do nosso serviço ou dificultá-lo estão proibidas. <br />
          (4) Sem o nosso consentimento expresso por escrito, o utilizador não
          pode extrair e/ou reutilizar sistematicamente partes dos nossos
          serviços. Em particular, sem o consentimento expressamente escrito da
          compramoscarrosusados.com, os utilizadores não podem fazer prospeção
          de dados, utilizar robôs ou programas de recompilação e extração de
          dados com o fim de obter partes essenciais do nosso serviço para a sua
          reutilização. <br />
          (5) Não se permite a integração ou a vinculação com o nosso serviço
          sem o consentimento expresso por escrito da compramoscarrosusados.com.{" "}
          <br />
          (6) A apresentação e o funcionamento do serviço podem variar segundo o
          tipo de acesso, por exemplo, através da internet ou de aplicações
          móveis. O direito de uso do serviço e das suas funções só é possível
          ao abrigo do atual estado da tecnologia. <br />
          (7) O utilizador concede gratuitamente à compramoscarrosusados.com,
          aos níveis temporal, geográfico e de conteúdos, o seguinte direito
          ilimitado e transmissível: de reutilizar on-line e off-line o conteúdo
          que tenha sido transmitido à compramoscarrosusados.com, assim como os
          dados do veículo e as fotos relacionadas com a avaliação do mesmo e
          efetuadas nas instalações da compramoscarrosusados.com. <br />
        </div>
        <br />
        <h5>4 - Garantia</h5>
        <div className="sobre">
          (1) Não assumimos qualquer responsabilidade pelos erros técnicos, em
          particular pela disponibilidade constante e ininterrupta da página web
          ou pela correta reprodução dos conteúdos introduzidos pelo utilizador.{" "}
          <br />
          (2) Em caso de não disponibilidade da oferta, pode entrar em contacto
          com o nosso Atendimento ao Cliente através do endereço de correio
          eletrónico: geral@compramoscarrosusados.com. <br />
        </div>
        <br />
        <h5>5 - Proteção de dados</h5>
        <div className="sobre">
          (1) A proteção de dados pessoais e a privacidade dos utilizadores na
          nossa página web são algo que a compramoscarrosusados.com leva muito a
          sério. Para assegurar a máxima proteção, é natural que cumpramos com
          todos os preceitos legais em matéria de proteção de dados. <br />
          (2) O sujeito responsável e gestor do serviço é: ON BOX, UNIPESSOAL,
          LDA., com sede na Praceta das Flores Nº 8 Loja – Alfragide – 2610-074
          Amadora. Para exercer os direitos de acesso, retificação, cancelamento
          e oposição dos ditos dados, em qualquer momento, pode enviar todas as
          suas questões relativas à política de privacidade, por correio
          eletrónico, para geral@compramoscarrosusados.com. <br />
          (3) A informação pessoal é aquela que pode ter sido atribuída
          individualmente a um utilizador. Isto inclui, por exemplo, nome, o
          endereço de correio eletrónico e, eventualmente, o número telefónico
          do utilizador. Através da nossa página web, recolhemos dados pessoais
          se estes tiverem sido postos à disposição pelo utilizador, mediante a
          introdução dos dados no nosso formulário de avaliação on-line. <br />
          (4) Utilizamos esta informação, na medida necessária, para oferecer os
          nossos serviços ao utilizador. O uso promocional dos dados faz-se com
          a finalidade única de fazer autopromoção da compramoscarrosusados.com
          ou de empresas afiliadas. Utilizamos o seu endereço de correio
          eletrónico para oferecer-lhe produtos ou serviços semelhantes. <br />
          (5) Em qualquer momento, é possível cancelar a subscrição do seu
          endereço de correio eletrónico e do seu número de telefone sem
          incorrer em gastos adicionais. A forma mais simples é enviar-nos um
          e-mail para o endereço geral@compramoscarrosusados.com com a palavra
          chave/assunto “cancelar subscrição”. <br />
        </div>
        <br />
        <h5>6 - Limitação de responsabilidade</h5>
        <div className="sobre">
          (1) Tratamos sempre de garantir que os serviços da
          compramoscarrosusados.com estejam disponíveis sem interrupção e livres
          de erros. Devido à natureza da internet, isto não pode ser garantido.
          Além disso, o acesso à nossa página pode ser interrompido ou
          restringido para fazer reparações, para manutenção ou para introduzir
          novos serviços. Tratamos sempre de limitar a frequência e a duração
          destas ditas interrupções. <br />
          (2) No decurso da utilização da nossa página web e dos seus serviços,
          respondemos de modo ilimitado pelos danos causados intencionalmente ou
          por negligência da compramoscarrosusados.com ou através dos seus
          representantes legais, funcionários ou agentes. <br />
          (3) Em caso de incumprimento do contrato, ON BOX, UNIPESSOAL, LDA. não
          se responsabilizará por nenhum dano. Por outro lado, a
          responsabilidade pelos danos causados por negligência limita-se
          àqueles derivados de uma atividade normal. <br />
          (4) As limitações anteriores não se aplicarão aos danos derivados de
          lesões de vida, corporais ou à saúde e outros danos premeditados.{" "}
          <br />
          (5) Onde se exclua o limite da responsabilidade de ON BOX, UNIPESSOAL,
          LDA., limitar-se-ão também funcionários, representantes e agentes.{" "}
          <br />
          (6) O conteúdo desta página web foi criado com o máximo cuidado. No
          entanto, ON BOX, UNIPESSOAL, LDA. está isento de qualquer
          responsabilidade derivada da exatidão, da exaustividade e da
          atualidade dos conteúdos. O uso dos conteúdos on-line faz-se sobre
          conta e risco do utilizador. <br />
        </div>
        <br />
        <h5>7 - Copyright e licença</h5>
        <div className="sobre">
          (1) Todos os logótipos, marcas comerciais, textos, imagens e demais
          dados da nossa página web estão submetidos a direitos de autor. Ao
          utilizar a nossa página, o utilizador não tem direito de,
          posteriormente, usar esta informação. A modificação, o processamento e
          o uso de qualquer tipo, em meios de comunicação, estão completamente
          proibidos. Outro uso só é possível com o consentimento prévio e por
          escrito da compramoscarrosusados.com.
          <br />
          (2) O uso não autorizado da nossa informação, assim como dos logótipos
          ou das marcas registadas de terceiros que aparecem na nossa página
          web, não é permitido e viola os nossos direitos e os dos outros.
          <br />
        </div>
        <br />
        <h5>8 - Cláusula de salvaguarda</h5>
        <div className="sobre">
          Se alguma das disposições destes Termos e Condições é ou mostra-se
          inválida, a validez das restantes disposições não se verá afetada.
          Esta disposição pode ser substituída por outras mais válidas e
          operantes que se aproximem o máximo possível à finalidade comercial
          prevista.
        </div>
        <br />
        <h5>9 - Lei aplicável, lugar de cumprimento e jurisdição</h5>
        <div className="sobre">
          O seguinte contrato está sujeito à lei portuguesa. A competência
          judicial corresponderá aos juízes e aos tribunais da sede do
          utilizador.
        </div>
        <br />
        <h3 className="subtitulo" id="informacao">
          Informação Legal
        </h3>
        <div className="sobre">
          On Box, Unipessoal Lda <br />
          <br />
          NIF: 510531407 <br />
          <br />
          Morada da Sede: Praceta das Flores, Nº 8, R/C Dtº - Loja <br />
          <br />
          2610-074 Amadora
          <br />
          <br />
          Capital Social: 20.000 €<br />
          <br />
          Forma Jurídica: Sociedade por quotas
          <br />
          <br />
          Contacto:
          <br />
          <br />
          Telefone: 215 838 788
          <br />
          <br />
          Página Web: https://www.compramoscarrosusados.com/
          <br />
          <br />
        </div>
        <h3 className="subtitulo" id="politica">
          Política de Privacidade
        </h3>
        <h5>Política de Privacidade</h5>
        <div className="sobre">
          A sua privacidade é importante para nós. É política do
          compramoscarrosusados.com respeitar a sua privacidade em relação a
          qualquer informação sua que possamos coletar no site
          www.compramoscarrosusados.com, e outros sites que possuímos e
          operamos. <br />
          <br />
          Solicitamos informações pessoais apenas quando realmente precisamos
          delas para lhe fornecer um serviço. Fazemo-lo por meios justos e
          legais, com o seu conhecimento e consentimento.
          <br />
          <br />
          Apenas retemos as informações recolhidas pelo tempo necessário para
          fornecer o serviço solicitado. Quando armazenamos dados, protegemos
          dentro de meios comercialmente aceitáveis para evitar perdas e roubos,
          bem como acesso, divulgação, cópia, uso ou modificação não
          autorizados.
          <br />
          <br />
          Não compartilhamos informações de identificação pessoal publicamente
          ou com terceiros, exceto quando exigido por lei.
          <br />
          <br />
          O nosso site pode ter links para sites externos que não são operados
          por nós. Esteja ciente de que não temos controle sobre o conteúdo e
          práticas desses sites e não podemos aceitar responsabilidade pelas
          suas respetivas políticas de privacidade. <br />
          <br />
          Você é livre para recusar a nossa solicitação de informações pessoais,
          entendendo que talvez não possamos fornecer alguns dos serviços
          desejados.
          <br />
          <br />
          O uso continuado de nosso site será considerado como aceitação de
          nossas práticas em torno de privacidade e informações pessoais. Se
          você tiver alguma dúvida sobre como lidamos com dados do usuário e
          informações pessoais, entre em contacto connosco.
          <br />
          <br />
        </div>
        <br />
        <h5>Compromisso do utilizador</h5>
        <div className="sobre">
          O usuário compromete-se a fazer uso adequado dos conteúdos e da
          informação que o compramoscarrosusados.com oferece no site e com
          caráter enunciativo, mas não limitativo:
          <br />
          <br />
          A) Não se envolver em atividades que sejam ilegais ou contrárias à boa
          fé a à ordem pública;
          <br />
          <br />
          B) Não difundir propaganda ou conteúdo de natureza racista, xenofobia,
          qualquer tipo de pornografia ilegal, de apologia ao terrorismo ou
          contra os direitos humanos;
          <br />
          <br />
          C) Não causar danos aos sistemas físicos (hardwares) e lógicos
          (softwares) do compramoscarrosusados.com, de seus fornecedores ou
          terceiros, para introduzir ou disseminar vírus informáticos ou
          quaisquer outros sistemas de hardware ou software que sejam capazes de
          causar danos anteriormente mencionados.
          <br />
          <br />
        </div>
        <br />
        <h5>Mudanças de política de privacidade</h5>
        <div className="sobre">
          A política de privacidade pode ser modificada a qualquer altura. Se a
          alteração em causa for considerada substancial e tiver implicações na
          forma a compramoscarrosusados.com utiliza e trata os dados pessoais,
          todos os interessados serão informados através de um aviso, nesse
          sentido, na página de entrada do website da compramoscarrosusados.com.
        </div>
        <h3 className="cookies" id="cookies">
          Política de cookies
        </h3>
        <h5>Sobre:</h5>
        <div className="sobre"></div>
        Cookies são informações que são guardadas através do seu browser.
        Guardam preferências a partir dos seus hábitos de navegação e do seu
        perfil, que apenas utilizamos para fins de manutenção e para analisar os
        padrões de tráfego do nosso site. Usamos cookies, apenas para rastrear
        visitas ao nosso site, não armazenamos dados pessoais. Pode alterar as
        seus preferências a qualquer momento no seu browser.
        <br />
        <br />
        <div className="sobre">
          A compramoscarrosusados.com utiliza “cookies” que permitem verificar
          se o utilizador já visitou o website anteriormente, visando
          personalizar os conteúdos e a informação disponíveis em função das
          anteriores visitas. O utilizador poderá aceitar ou rejeitar “cookies”,
          bastando que configure o seu browser de acordo com o que pretender.
          Caso opte pela rejeição de “cookies”, o utilizador poderá não
          conseguir experimentar todas as potencialidades e funcionalidades
          disponibilizadas pelo website.
        </div>
        <br />
        <h5>
          Explicacão e duracão das cookies do Google Analytics, Google Ads e
          Google Tag Manager:
        </h5>
        <div className="sobre">
          <h6>- Google Analytics:</h6> <br />
          _ga: Utilizado para identificar únicos visitantes. Dura 2 anos. <br />
          _gid: Utilizado para identificar únicos visitantes. Dura 24 horas.{" "}
          <br />
          _gat: Utilizado para limitar a taxa de solicitação. Dura 1 minuto.{" "}
          <br />
          <br />
          <h6>- Google Ads:</h6> <br />
          {
            "gac<property-id>: Utilizado para armazenar informações de conversão para campanhas de publicidade. Dura 90 dias."
          }
          <br />
          <br />
          <h6>- Google Tag Manager:</h6>
          <br />
          {
            "gtm<property-id>: Utilizado para controlar a versão do container. Dura 1 ano."
          }
          <br /> <br />
          Observe que esta lista pode ser alterada e atualizada pela Google e
          que alguns destes cookies podem ser utilizados também para outras
          funcionalidades. É importante mencionar que esta lista é uma amostra e
          pode haver variações dependendo da configuração da sua conta.
        </div>
      </div>
    </Card>
  );
};

export default Outros;
