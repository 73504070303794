import React from 'react';
import Card from '../shared/UIElements/Card';
import '../contacto/Contacto.css'

const Sobre = () => {
    return (
        <Card className='card-content'>
            <h1>QUEM SOMOS</h1>
            <hr />
            <div className='sobre'>
            Somos a compramoscarrosusados.com, uma empresa especializada na compra e venda de automóveis usados em Portugal, atividade que iniciamos do zero com base na aprendizagem que adquirimos nas maiores empresas nacionais do setor automóvel.<br /><br />
            Compramos todo o tipo de carros usados, em especial os que possuem características para venda de automóveis usados em stand a cliente final, procuramos viaturas usadas sem histórico de acidentes graves de forma a podermos vender carros usados com garantia e confiança total. <br /><br />
            Estamos no mercado de compra de carros usados desde 1998 e, por isso, somos uma das empresas com mais tradição neste segmento de mercado.<br /><br />
            Estamos localizados Praceta das Flores Nº 8 em Alfragide, de onde desenvolvemos toda a nossa atividade on-line e atendimento físico aos clientes localizados em todas as regiões da Portugal Continental.<br /><br />
            Prestamos um serviço diferenciado, pois utilizamos as melhores práticas. Cada processo de venda de automóvel usado é tratado de forma individual e personalizada, somos uma empresa nacional que conhece as especificidades e a cultura do nosso povo. <br /><br />
            A nossa equipa, principalmente os avaliadores, são especializados na avaliação de carros usados, fruto da constante formação, bem como técnicas que foram desenvolvidas e aprimoradas pela nossa empresa de compra e venda de veículos usados ao longo de mais de 24 anos de história.<br /><br />
            O nosso objetivo é oferecer um serviço de excelência a quem pretende vender carros usados, procurar oferecer um valor justo, passar um sentimento de confiança, tranquilidade, honestidade, de uma forma fácil, cómoda, rápida, e acima de tudo em segurança.<br /><br />
            </div>
        </Card>
    );
}

export default Sobre;