import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-items">
        <div className="footer-item">
          A{" "}
          <a href="https://www.compramoscarrosusados.com/">
            CompramosCarrosUsados.com
          </a>{" "}
          é uma plataforma online que está a revolucionar o mercado de carros
          usados em Portugal, ajudando as pessoas que querem vender o seu carro
          o mais rápido possível.
        </div>
        <div className="footer-item">
          <h5>Contactos</h5>
          <p>
            <i class="fa fa-phone" aria-hidden="true"></i> +351 967 323 907
          </p>
          <p>
            <i class="fa fa-envelope" aria-hidden="true"></i>{" "}
            geral@compramoscarrosusados.com
          </p>
          <div>
            <i class="fa fa-location-arrow" aria-hidden="true"></i> Praceta das
            Flores Nº 8 Loja
          </div>
          <div>Quinta Grande de Alfragide</div>
          2610-074 Amadora
        </div>
        <div className="footer-item">
          <h5>Sobre nós</h5>
          <a href="/sobre">Quem Somos</a>
          <a href="/perguntas">Perguntas Frequentes</a>
        </div>
        <div className="footer-item">
          <h5>Outros</h5>
          <a href="/outros#termos">Termos e Condições</a>
          <a href="/outros#informacao">Informação legal</a>
          <a href="/outros#politica">Política de Privacidade</a>
          <a href="/outros#cookies">Política de cookies</a>
          <a
            href="https://www.livroreclamacoes.pt/Inicio/"
            target="_blank"
            rel="noreferrer"
          >
            Livro de Reclamações
          </a>
        </div>
      </div>
      <hr />
      <p>© www.compramoscarrosusados.com 2021-2022</p>
    </div>
  );
};

export default Footer;
