import React from 'react';
import Form from 'react-bootstrap/Form';

import './Select.css';

function Select(props) {
    return (
        <Form.Group className="mb-3">
            <Form.Label className='label'>{props.label}</Form.Label>
            <Form.Select aria-label={props.title} onChange={props.onChange}>
                <option>{props.title}</option>
                {props.options.map((option, index) => (
                    <option value={option} key={index}>{option}</option>
                ))}
            </Form.Select>
        </Form.Group>
    );
}

export default Select;