import React from 'react';

import Form from 'react-bootstrap/Form';

import './Input.css';

function Input(props) {
    return (
        <Form.Group className="mb-3">
            <Form.Label className='label' htmlFor={props.id}>{props.label}</Form.Label>
            <Form.Control
                className='input-field'
                type={props.type}
                id={props.id}
                aria-describedby={props.label}
                placeholder={props.placeholder}
                max={props.max ? props.max : null}
                ref={props.inputRef}
                required
                multiple={props.multiple ? true : false}
                name={props.name}
                onChange={props.onChange}
            />
        </Form.Group>
    );
}

export default Input;