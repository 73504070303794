import React from 'react';

import Form from 'react-bootstrap/Form';

import './Checkbox.css';

function Checkbox(props) {
    return (
        <div key='inline-checkbox' className="mb-3 checkbox">
            <Form.Label>{props.label}</Form.Label>
            <br />
            {props.options.map((option, index) => (
                <Form.Check   
                    key={index}           
                    label={option}
                    name={props.group}
                    type='checkbox'
                    id={option}
                    className='item'
                    onChange={props.changeHandler}
                />
            ))}
        </div>
    );
}

export default Checkbox;